<template>
    <div v-if="open" ref="modalEl" class="modal">
        <div
:class="'modal-container ' + (props.isMatrixModal ? 'modal-container--matrix' : '')"
             @mousedown="getSelectedContainer"
             @mouseup="updateModal"
        >
            <div class="modal-box" @click="$event.stopPropagation()">
                <div v-if="showXButton" class="modal-box__close">
                    <div class="modal-box__close-button" @click="$emit('update:open', false)">
                        <AtomIcon name="x" width="30" />
                    </div>
                </div>
                <div v-if="title || text" class="modal-box__content">
                    <div v-if="title" class="modal-box__title">{{ title }}</div>
                    <div v-if="text" class="modal-box__text" v-html="$sanitizeHtml(text)"/>
                </div>
                <div v-else ref="customContent" class="modal-box__custom-content">
                    <slot />
                </div>
                <div v-if="buttons?.length" class="modal-box__bottom">
                    <div class="modal-box__buttons">
                        <AtomButton
                            v-for="(button, index) in buttons"
                            :key="index"
                            class="modal-box__button"
                            :disabled="buttonDisabled"
                            :class="button.class"
                            :text="button.text"
                            :icon="button.icon"
                            :icon-right="button.iconRight ? button.iconRight : false"
                            :circular="button.circular ? button.circular : false"
                            :type="button.type ? button.type : 'primary'"
                            :size="button.size ? 'l' : button.size"
                            @click="buttonClicked(index, $event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ModalButton } from '~/composables/types/ui';

const props = withDefaults(defineProps<{
    open: boolean,
    title?: string,
    text?: string,
    buttons?: ModalButton[],
    isMatrixModal?: boolean,
    preventBackgroundScroll?: boolean
    buttonDisabled?: boolean,
    showXButton?: boolean
}>(), {
    isMatrixModal: false,
    preventBackgroundScroll: false,
    buttonDisabled: false,
    showXButton: true,
    title: undefined,
    text: undefined,
    buttons: undefined,
});

const emit = defineEmits<{
    (e: 'update:open', open: boolean): void,
    (e: 'buttonClicked', index: number, event: PointerEvent): void
}>();

const modalEl = ref();
const elMousedownSelected: Ref<HTMLElement | null> = ref(null);
const customContent = ref<HTMLElement | null>(null);
const { $sanitizeHtml } = useNuxtApp();

const getSelectedContainer = (e: MouseEvent) => {
    elMousedownSelected.value = e.target as HTMLElement;
};

const updateModal = (e: MouseEvent) => {
    e.stopPropagation();
    const eventTarget = e.target as HTMLDivElement;

    if (eventTarget?.classList.contains('modal-container') &&
        elMousedownSelected.value?.classList.contains('modal-container')
    ) {
        emit('update:open', false);
    }
};

const buttonClicked = (index: number, event: PointerEvent) => {
    emit('buttonClicked', index, event);
};

const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
        const modals = Array.from(document.querySelectorAll('.modal'));
        if (modalEl.value === modals[modals.length - 1]) {
            emit('update:open', false);
        }
    }
};

watch(() => props.open, async(newValue) => {
    if (newValue && props.preventBackgroundScroll) {
        document.addEventListener('keydown', onWindowKeyDown);
        document.body.style.overflow = 'hidden';
        await nextTick();
        modalEl.value.querySelector('[autofocus]')?.focus();
    } else {
        document.body.style.overflowX = 'hidden';
        document.body.style.overflowY = 'scroll';
        document.removeEventListener('keydown', onWindowKeyDown);
    }
});

onBeforeUnmount(() => {
    document.removeEventListener('keydown', onWindowKeyDown);
});
</script>

<style lang="scss" scoped>
.modal {
    z-index: $setting-zi-modal;
    position: fixed;
    inset: 0;
    overflow: hidden;
    overscroll-behavior: contain;

    &-container {
        position: relative;
        width: 100%;
        height: 100%;

        @include helper-color-bg(gray-11);
        @include helper-flex-centered();
    }

    &-box {
        position: relative;
        margin: auto;
        min-width: rem(600);
        min-height: rem(238);
        max-height: 100%;
        padding: sp(m);

        @include helper-color-bg(white);
        @include helper-border-radius(rem(10));

        @include helper-breakpoint-media-max($lg) {
            min-width: calc(100% - #{sp(l)});
            margin: 0 auto;
        }

        &__close {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__close-button {
            cursor: pointer;
            margin-left: auto;
            width: rem(30);
            height: rem(30);
            margin-bottom: sp(xs);

            svg {
                stroke: $setting_color_corporate-blue;
                stroke-width: rem(2);
            }
        }

        &__content {
            max-width: rem(552);
            flex-direction: column;
        }

        &__custom-content {
            max-width: 95vw;
            max-height: 75vh;
            overflow: auto;
            overscroll-behavior: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include helper-breakpoint-media-max($lg) {
                min-width: calc(100% - #{sp(l)});
                margin: 0 auto;
            }
        }

        &__title {
            @include helper-font-size(4);
            @include helper-font-weight(bold);
        }

        &__text {
            margin-top: sp(xs);

            @include helper-font-size(6);
        }

        &__buttons {
            margin-top: sp(l);
            margin-left: auto;
            display: flex;
            justify-content: flex-end;

            .button {
                width: rem(268);

                &:not(:first-child) {
                    margin-left: sp(s);
                }
            }
        }
    }
}
</style>
